<template>
	<div>
		<shutter-panel
			ref="mouvementPanel"
            :shutters="[
                {
                    name: 'write-mouvement',
                    title: this.$t('acte.trigger.declencher.mouvement'),
                    default: false
                },
                {
                    name: 'carnet-adresse',
                    title: this.$t('mouvement.carnet_adresse'),
                    default: false
                },
            ]"
        >

            <template 
                v-slot:write-mouvement
                ref="shutterCard_writeMouvement"
            >
                 <WriteMouvementTriggered :horses="horses" :date_acte="date_acte" :addOrEditReady="addOrEditReady" :openCarnetAdresse.sync="openCarnetAdresse" />
            </template>

            <template 
                v-slot:carnet-adresse
                ref="shutterCard_carnetAdresse"
            >
                 <CarnetAdresseTriggered :addOrEditReady="addOrEditReady" />
            </template>


        </shutter-panel>
	</div>
</template>

<script type="text/javascript">
    import Shutter from "@/mixins/Shutter.js"
 	import WriteMouvementTriggered from '@/components/Triggers/WriteMouvementTriggered'
 	import CarnetAdresseTriggered from '@/components/Triggers/CarnetAdresseTriggered'

	export default {
		name: "AjoutMouvementTriggered",
        mixins: [Shutter],
	    // props: ['horses', 'save_trigger', 'triggered_list', 'add_triggered', 'remove_triggered', 'date_acte', 'addOrEditReady'],
        props: {
            horses: { type: Array, default: () => [] },
            save_trigger: { type: Boolean, default: false },
            triggered_list: { type: Array, default: () => [] },
            add_triggered: { type: Object, default: () => {} },
            remove_triggered: { type: Object, default: () => {} },
            date_acte: { type: Date, default: () => (new Date()) },
            addOrEditReady: { type: Boolean, default: () => false },
        },
	    data () {
	    	return { 
	    		openCarnetAdresse: "",
		    }
	    },
	    mounted() {
	    	this.init_component()
	    },
	    methods: {
	    	init_component() {
                this.shutterPanel().open("write-mouvement")
	    	},

            onOpen() {
                this.shutterPanel().open("write-mouvement")
				// this.$refs.mouvementPanel.updateInternalShutters()
    			// this.$emit(`ShutterPanel::refreshTree`)
            },
	    },
	    watch: {
	    	'openCarnetAdresse' (val){
	    		if(val)	{
	    			this.shutterPanel().open('carnet-adresse')
					// this.$refs.mouvementPanel.updateInternalShutters()
    				// this.$emit(`ShutterPanel::refreshTree`)
	    		}
	    	}
	    },
	    components: {
	    	WriteMouvementTriggered,
	    	CarnetAdresseTriggered,
	    	LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
        }
	}
</script>