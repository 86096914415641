import InvoiceDetailsTransformer from './InvoiceDetailsTransformer'
import InvoiceTrackingTransformer from './InvoiceTrackingTransformer'
import Transformer from './Transformer'

export default class InvoiceTransformer extends Transformer {

	table = 'invoice'

    transform(invoice) {
        return {
			'invoice_id': invoice.invoice_id,
			'invoice_num': invoice.invoice_num,
			'invoice_date': invoice.invoice_date,
			'invoice_tiers': invoice.invoice_tiers,
			'invoice_author': invoice.invoice_author,
			'invoice_ttc': invoice.invoice_ttc,
			'invoice_ht': invoice.invoice_ht,
			'invoice_vat': invoice.invoice_vat,
			'invoice_balance': invoice.invoice_balance,
			'invoice_pdf_url': invoice.invoice_pdf_url,
			'invoice_send_mail': invoice.invoice_send_mail,
			'invoice_state': invoice.invoice_state,
			'invoice_accountingplan': invoice.invoice_accountingplan,
        }
	}

	invoice_tracking(item) {
		return this.fetchRelationship(item, 'invoice_tracking')
		.then(trackings => {
			return InvoiceTrackingTransformer.process(trackings)
		})
	}

	invoice_details(item) {
		return this.db().t('invoice_details')
		.then(table => {
			return table.where({
				invoicedetails_invoice: parseInt(item.invoice_id)
			})
		})
		.then(col => {
			return col.transform(new InvoiceDetailsTransformer('withOnlyContract'))
		})
	}

	fetchWithInvoiceTrackingAndTiers(col) {
		this.additionalColumns({
			'invoice_tracking': this.invoice_tracking,
			'invoice_details': this.invoice_details
		})

		return col.with({
			'invoice_tiers': 'invoice_tiers',
			'invoice_author': 'invoice_author'
		})
	}

	transformWithInvoiceTrackingAndTiers(invoice) {
        return {
			'invoice_id': invoice.invoice_id,
			'tracking': invoice.invoice_tracking,
			'invoice_num': invoice.invoice_num,
			'invoice_date': invoice.invoice_date,
			'tiers': invoice.invoice_tiers,
			'author': invoice.invoice_author,
			'invoice_author': invoice.invoice_author,
			'invoice_ttc': invoice.invoice_ttc / 100,
			'invoice_ht': invoice.invoice_ht / 100,
			'invoice_vat': invoice.invoice_vat / 100,
			'invoice_balance': invoice.invoice_balance / 100,
			'invoice_pdf_url': invoice.invoice_pdf_url,
			'invoice_send_mail': invoice.invoice_send_mail,
			'invoice_state': invoice.invoice_state,
			'invoice_accountingplan': invoice.invoice_accountingplan,
			'invoice_exported': invoice.invoice_exported,
			'invoice_printed': invoice.invoice_printed,
			'details': invoice.invoice_details,
			'invoice_ref_client': invoice.invoice_ref_client,
			'invoice_dossier_interne': invoice.invoice_dossier_interne
        }
	}

	fetchLightWithTiers(col) {
		return col.with({
			'invoice_tiers': 'invoice_tiers'
		})
	}

	transformLightWithTiers(invoice) {
        return {
			'invoice_id': invoice.invoice_id,
			'tiers': invoice.invoice_tiers
        }
	}
}