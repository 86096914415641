<template>
    <div>
        <hr>
        <label v-if="hasFacturationAccess">{{ $t("mouvement.tiers_cheval_origine") | capitalize }}</label>
        <label v-else>{{ $t("mouvement.provenance") | capitalize }}</label>

        <div class="row" id="lieu">
            <div class="col-12">
                <div class="row mb-2">
                    <div class="col">
                        <SearchTiers 
                            v-if="hasFacturationAccess"
                            :tiers_selected.sync="tiers"
                            :presearch="presearch_tiers"
                        />
                        <e-select
                            v-else
                            track-by="lieu_id"
                            v-model="lieu"
                            :options="all_lieux"
                            :placeholder="$t('mouvement.rechercher_lieu')"
                            :sortable="false"
                            :internal-search="false"
                            @input="setLieu"
                            ref="input"
                        >
                            <template slot="singleLabel" slot-scope="{ option }">{{ option.lieu_label }} {{ option.lieu_adresse }} {{ option.lieu_cp }} {{ option.lieu_ville }}</template>
                            <template slot="option" slot-scope="{ option }">{{ option.lieu_label }} {{ option.lieu_adresse }} {{ option.lieu_cp }} {{ option.lieu_ville }}</template>
                        </e-select>
                    </div>
                    <div class="col-auto">
                        <button class="btn btn-secondary" @click="addTierOrLieu"><font-awesome-icon :icon="['far', 'plus']" /></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
import { EventBus } from "EventBus"
import _isEmpty from 'lodash/isEmpty'
import ShutterMouvements from '@/mixins/shutters-manager/Mouvements.js'
import Shutter from "@/mixins/Shutter.js"
import Tiers from "@/mixins/Tiers.js"

export default {
    mixins: [Shutter, ShutterMouvements, Tiers],
    props: {
        provenance: { type: Object, default: () => ({}) },
        all_lieux: { type: Array, default: () => ([])}
    },
    data () {
        return {
            provenance_local: {
                lieu_label: '',
                lieu_adresse: '',
                lieu_cp: '',
                lieu_ville: '',
                lieu_type: 3
            },
            tiers: {},
            lieu: {},
            presearch_tiers: ''
        }
    },
    created() {
        this.init_component()
    },
    methods: {
        async init_component() {
            if(!_isEmpty(this.provenance) && this.provenance.lieu_label) {
                this.provenance_local = this.provenance
                if(this.provenance.tiers_id) {
                    const tiers = await this.loadTier(this.provenance.tiers_id)
                    this.presearch_tiers = tiers.tiers_rs
                }
            }
        },
        setLieu() {
            this.provenance_local = this.lieu
        },
        lieuFormSubmit() {
            EventBus.$emit('RefreshAllLieux')
        },
        addTierOrLieu() {
            if(this.hasFacturationAccess) {
                this.addTier()
            }
            else {
                this.addLieu()
            }
        },
        addTier() {
            this.openAddTier()
        },
        addLieu() {
            this.openAjoutLieu()
        },
        setTier(a) {
            this.presearch_tiers = a
        }
    },
    computed: {
        hasFacturationAccess() {
            return this.$store.state.userAccess.hasFacturationAccess
        },
    },
    watch: {
        'provenance_local' (val) {
            return this.$emit('update:provenance', val)
        },

        'tiers' (val) {
            if(val) {
                this.provenance_local = {
                    lieu_adresse: val.tiers_address1,
                    lieu_cp: val.tiers_postalcode,
                    lieu_label: val.tiers_rs,
                    lieu_ville: val.tiers_town,
                    lieu_type: 3,
                    tiers_id: val.tiers_id
                }
            }
        },
        'provenance': {
            handler(val) {
                this.init_component()
            },
            deep: true
        },
    },
    components: {
        SearchTiers : () => import('@/components/Contract/SearchTiers'),
    }
}
</script>
