import Transformer from './Transformer'

export default class InvoiceTrackingTransformer extends Transformer {

	table = 'invoice_tracking'

    transform(invoice_tracking) {
        return {
			'invoicetracking_id': invoice_tracking.invoicetracking_id,
			'invoicetracking_invoice': invoice_tracking.invoicetracking_invoice,
			'invoicetracking_type': invoice_tracking.invoicetracking_type,
			'invoicetracking_date': invoice_tracking.invoicetracking_date,
			'invoicetracking_message': invoice_tracking.invoicetracking_message,
        }
	}
}