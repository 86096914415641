<template>
    <div>
        <h2>{{ $t('mouvement.carnet_adresse') }}</h2>
        
        <CustomTable v-show="list_state"
            id_table="carnet_adresse"
            :items="lieux"
            :busy.sync="table_busy"
            primaryKey="lieu_id"
            :hide_if_empty="true"
        />
    </div>
</template>

<script type="text/javascript">
    import { EventBus } from 'EventBus'
    import Lieux from "@/mixins/Lieux.js"
    import Horse from "@/mixins/Horse.js"
    import Tools from "@/mixins/Tools.js"
    import Navigation from '@/mixins/Navigation.js'
    import CustomTable from "GroomyRoot/components/Table/CustomTable"

	export default {
		name: "CarnetAdresseTriggered",
        mixins: [Lieux, Horse, Tools, Navigation],
        props: [
            'tab_state_screen', // à utiliser lors de l'utilisation du CA sur un composant (les états doivent correspondre aux noms de route children)
            'from',
            'residences_only_param',
            'specific_eventbus',
        ],
        components: {
            CustomTable
        },
		data () {
			return {
                horse_id: null,
                lieux: null,
                table_busy: true,
                list_state: true,

                root_path: '',
                state: '',
                state_index: 0,
                gestion_bool: false,  // passe à true au clic sur "Gerez vos adresse"
                compo_name_eventbus: 'WriteMouvement',
                residences_only: false,
                only_lieux: false,

                tab_states: {
					'lieu_choice': 'mouvement.carnet_adresse',
					'gerer_lieu': 'lieu.gerer_lieu',
					'ajout_lieu': 'lieu.ajouter_lieu'
				},
                states: ['lieu_choice', 'gerer_lieu', 'ajout_lieu'],
                gerer_lieu_url: 'gerer_lieu',
                ajout_lieu_url: 'ajout_lieu',

                events_tab: {
                    'CarnetAdresse::set_last_state': this.set_last_state,
                    'TableAction::AddLieu': this.addLieu,
                    'TableAction::EditLieu': this.editLieu,
                    'TableAction::DeleteLieux': this.rmLieux,
                    'TableAction::SelectLieu': this.chooseResidence,
                    'CarnetAdresse::getSaveStatus': this.getSaveStatus,
                }
			}
		},
		mounted() {
            this.init_component()
		},
		methods: {
            async init_component() {
                this.selected_residence = this.$route.params.lieu_id
                this.horse_id = this.$route.params.horse_id
                this.only_lieux = this.$route.params.only_lieux
                this.root_path = this.$route.name


                if(this.$route.params.only_residence) {
                    this.residences_only = this.$route.params.only_residence
                }
                else if(this.residences_only_param) {
                    this.residences_only = this.residences_only_param
                }

                if(this.specific_eventbus) {
                    this.compo_name_eventbus = this.specific_eventbus
                }

                // Si on spécifie une liste d'état pour un écran donné
                if(this.tab_state_screen) {
                    const trads = Object.values(this.tab_states)
                    let tab_tmp = {}

                    this.tab_state_screen.forEach((state, index) => {
                        tab_tmp[state] = trads[index]
                        this.states[index] = state

                        if(index == 1) {
                            this.gerer_lieu_url = state
                        }
                        else if(index == 2) {
                            this.ajout_lieu_url = state
                        }
                    })

                    this.tab_states = tab_tmp
                }

                this.update_list_residences()
                this.set_actual_status()
            },

            chooseResidence(residence) {
                if(!this.gestion_bool) {
                    EventBus.$emit(this.compo_name_eventbus+'::backFromCarnetAdresse', residence)

                    if(this.from != 'horseFiche') {
                        this.goBack()
                    }
                }
            },

            addLieu() {
                this.$router.push({ name: this.ajout_lieu_url, params: { sendStatusToParent: 'CarnetAdresse::getSaveStatus' }})
                this.list_state = false
            },

            editLieu(lieu) {
                this.$router.push({ name: this.ajout_lieu_url, params: { sendStatusToParent: 'CarnetAdresse::getSaveStatus', lieu_id: lieu.lieu_id }})
                this.list_state = false
            },

            rmLieux(lieux) {
                const ids = this.getArrayObjProperty(lieux, 'lieu_id')
                this.deleteLieu(ids).then(() => {
                    this.successToast()
                    this.update_list_residences()
                })
                .catch(() => {
                    this.failureToast()
                })
            },

            getSaveStatus(status) {
                if(status == 'success') {
                    this.successToast()
                    this.update_list_residences()
                    this.goBack()
                    this.list_state = true
                }
                else {
                    this.failureToast()
                }
            },

            async update_list_residences() {
                this.table_busy = true
                this.lieux = await this.getLieux()
                this.table_busy = false
            },

            /* Toasts d'infos */
            successToast() {
                const params = {
                    title: this.getTrad('global.information'),
                    body: this.getTrad('toast.info_modif_succes'),
                    type: 'success'
                }
                EventBus.$emit('Header::displayToast', params)
            },
            failureToast() {
                const params = {
                    title: this.getTrad('global.information'),
                    body: this.getTrad('toast.info_modif_failed'),
                    type: 'danger'
                }
                EventBus.$emit('Header::displayToast', params)
            }
		},
        watch: {
            '$route' (to) {
                // Si on revient sur cette route, on raffiche la liste principale
                if (this.root_path == to.name) {
                    this.list_state = true
                }
            }
        }
	}
</script>
