<template>
	<div>
		<!-- ajout et sauvegarde - écran principal -->
		<form @submit.prevent="validForm">

            <!-- Si on veut ajouter un nouveau lieu directement -->
			<div class="form-group">
				<label>{{ $t("mouvement.date_mouvement") }}<sup>*</sup></label>
                <e-datepicker id="date_ouverture" v-model="mouvement_date"></e-datepicker>
			</div>

            <!-- Carnet d'adresse pour choisir un lieu - au click, on affiche le composant CarnetAdresse -->
			<label>{{ $t("mouvement.lieu_mouvement") }}<sup>*</sup></label>
			<span class="btn-link float-right" v-on:click.stop.prevent="displayCarnetAdresse" title="">
				{{ $t("mouvement.carnet_adresse") }} <font-awesome-icon :icon="['fal', 'chevron-circle-right']" />
			</span>

            <!-- Formulaire ajout lieu -->
            <div class="row" id="lieu">
                <div class="col-12">
                    <!-- Si les infos sont déjà renseignées -->
                    <div @click="editProposedLieu" class="form-group" v-if="(lieu.lieu_adresse && lieu.lieu_cp && lieu.lieu_ville) || lieu_from_ca">
                        <div class="form-control">
							<b>{{ lieu.lieu_label }}: </b>
							<span>{{ lieu.lieu_adresse }}, {{ lieu.lieu_cp }} {{ lieu.lieu_ville | capitalize }}</span>
						</div>
                    </div>

                    <!-- Sinon, on affiche le formulaire -->
                    <div v-if="edit_lieu_ca || !lieu_from_ca">
                        <div class="form-group">
                            <input class="form-control chooseplace" :placeholder="$t('lieu.label')" v-model="lieu.lieu_label" required>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <div class="col-4">
                                    <input class="form-control" :placeholder="$t('lieu.adresse')" v-model="lieu.lieu_adresse">
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="number" :placeholder="$t('lieu.cp')" id="cp" v-model="lieu.lieu_cp">
                                </div>
                                <div class="col-4">
                                    <input class="form-control" :placeholder="$t('lieu.ville')" v-model="lieu.lieu_ville">
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Raison du mouvement -->
                    <div class="form-group">
                        <label>{{ $t("mouvement.raison_mouvement") }}<sup>*</sup></label>
                        <input class="form-control" type="text" id="mouvement_raison" :placeholder="$t('mouvement.raison_mouvement')" v-model="mouvement_raison" required>
                    </div>

                    <!-- Voir le computed can_define_retour pour les cas -->
                    <div class="form-group" v-if="can_define_retour">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="realise" v-model="date_retour_checked">
                            <label class="custom-control-label" for="realise">{{ $t("mouvement.definir_date_retour") }}</label>
                        </div>
                    </div>

                    <div class="form-group" v-if="date_retour_checked">
    					<label>{{ $t("mouvement.date_retour") }} <sup>*</sup></label>
    					<div class="input-group">
    						<input type="date" v-model="date_retour" class="form-control" required>
    						<div class="input-group-append">
    							<span class="input-group-text"><font-awesome-icon :icon="['fal', 'calendar-alt']" /></span>
    						</div>
    					</div>
    				</div>
                </div>
            </div>

			<!-- Provenance à préciser si le cheval entre sur la structure -->
			<div v-if="askProvenance">
				<AskProvenance ref="askProvenance" :provenance.sync="provenance_lieu" :displayCarnetAdresse="displayCarnetAdresseProvenance" />
			</div>

            <!-- Bas de page -->


				<!-- sendFormCheck -->

		</form>
	</div>
</template>

<script type="text/javascript">
	import Vue from 'vue'
	import { EventBus } from 'EventBus';
    import Mouvement from "@/mixins/Mouvements.js";
	import Lieux from "@/mixins/Lieux.js";
    import Horse from "@/mixins/Horse.js";
    import Navigation from "@/mixins/Navigation.js";
    import ErrorAlert from "GroomyRoot/components/Alert/ErrorAlert";
    import LoadingSpinner from 'GroomyRoot/components/Logos/LoadingSpinner_35';
	import AskProvenance from "@/components/Mouvements/AskProvenance";


	export default {
		name: "WriteMouvementTriggered",
        mixins: [Mouvement, Lieux, Horse, Navigation],
        // props: ['addOrEditReady', 'horses', 'date_acte', 'openCarnetAdresse'],
        props: {
            horses: { type: Array, default: () => [] },
            date_acte: { type: Date, default: () => (new Date()) },
            addOrEditReady: { type: Boolean, default: () => false },
            openCarnetAdresse: { type: Boolean, default: () => false}
        },
		data () {
			return {
                state: 'main_form',
                lieu_from_ca: false, // passe à true si on a séléctionné une adresse dans le carnet d'adresse, ou si on edit un mouvement
				edit_lieu_ca: false,
                message_erreur_code: '',
                from_asset: '', // Vue précédente

                /* obj mouvement */
                mouvement: {},
                mouvement_id: 0,
                mouvement_date: null,
                date_retour: '',
                date_retour_checked: false,
                mouvement_raison: '',
				origine_cheval: '', // si on est dans le tunnel d'ajout de cheval

                /* Nav et formulaires */
                state_index: 0,
                tab_states: {
					'main_form': 'mouvement.ajouter_mouvement',
					'carnet_adresse': 'mouvement.carnet_adresse',
				},
                mouv_modif_title: 'mouvement.modifier_mouvement',
                required_values: [
                    'mouvement_date',
                    'mouvement_raison'
                ],
                ready : false,
				last_mouvement: null,
				previous_mouv: null, // mouvement précédent l'actuel (cas d'une édition)
				lieu: { lieu_id: '', lieu_label: '', lieu_adresse: '', lieu_cp: '', lieu_ville: '', lieu_type: '3' }, // lieu de destination du mouvement
				lieu_bak: '',
				provenance_lieu: { lieu_id: '', lieu_label: '', lieu_adresse: '', lieu_cp: '', lieu_ville: '', lieu_type: '' },
				provenance_lieu_bak: '',
				provenance_mouv: { lieu_id: '', lieu_label: '', lieu_adresse: '', lieu_cp: '', lieu_ville: '', lieu_type: '' },
				provenance_mouv_bak: '',
				provenance_edit: false,

				ca_origine: '', // origine du clic, qui a ouvert le carnet d'adresse. Peut être lieu_mouvement ou provenance
				first_mouv: false, // passe à true si aucun mouvement n'est encore enregistré
				events_tab: {
					'WriteMouvement::backFromCarnetAdresse': this.backFromCarnetAdresse
				},
				prev_mouv_working: true, // booléens du statut de chargement des données
				last_mouv_working: true,
				horse_resid_working: true,
			}
		},
		mounted() {
            this.init_component()
		},
		methods: {
            async init_component() {
                this.mouvement_date = this.date_acte

				this.prepare_form()

				this.prev_mouv_working = false
				this.backupLieuResidence()

                // S'il s'agit du tout premier mouvement
                if(this.isFirstMouvement) {
                    this.lieu.lieu_type = 1
                }

				// Si on a un seul cheval
				if(this.singleAddHorse) {
					this.getLastMouvement(this.horse_ids[0])
					.then(async (result) => {
						if(Array.isArray(result)) {
							result = result[0]
						}
                        this.last_mouvement = result

                        // Dans le cas d'un ajout de mouvement, le mouvement précédent est le dernier enregistré
                        this.previous_mouv = result

						// Si le dernier mouvement est une entrée, la proposition de la provenance n'est pas nécessaire puisque le mouvement saisit s'agira d'une sortie
						if(result.mouvement_type == 1) return

						// En revanche, si le mouvement précédent est une sortie, il s'agit alors actuellement d'une entrée. On preload la destination qui sera la résidence
                        await this.preloadDestinationResidence()

						// On autocomplete la provenance par le mouvement précédent
						this.extractMouvData(result, 'provenance_mouv')
                        this.extractMouvData(result, 'provenance_lieu')
						this.backupMouvProvenance()
						this.backupLieuProvenance()
					})
					.catch(e => {
						console.log("WriteMouvement::init_component => ", e)
					})
					.finally(() => {
                        this.last_mouv_working = false
                    })
				}
				else {
					this.last_mouv_working = false
					// Comment gérer le cas de plusieurs chevaux ?
				}

                this.prepare_form()
            },

            async prepare_form() {
                // Si on vient de l'ajout de cheval, le lieu où se trouvera le cheval sera toujours une résidence. Donc lieu_type et mouvement_type à 1
                if(this.isAjoutChevalTunnel) {
                    this.lieu.lieu_type = 1

					await this.preloadDestinationResidence()
                    this.backupLieuResidence()

					this.prev_mouv_working = false
					this.last_mouv_working = false
                }
                else {
                    this.set_actual_status()
                }
            },

			async preloadDestinationResidence() {
				// On regarde si on a déjà des résidences, si oui, on auto complete avec la dernière saisie
				const last_resid = await this.getLastResidence()
				.catch(e => {
					console.log("WriteMouvement::prepare_form => error on getLastResidence:", e)
				})

				if(last_resid && last_resid.lieu_id) {
					this.lieu_from_ca = true
					this.ca_origine = 'lieu_mouvement'
					this.setObjectVars(last_resid)
				}
            },

            // On vérifie les champs du formulaire, et en fonction de mouvement_id, on update ou on insert le mouvement
            sendFormCheck() {
                if(this.checkForm(this.required_values)) {
                    this.message_erreur_code = "formulaire.erreur_champs_non_remplis"
                }
            },

			async validForm() {
                // On retourne un tableau d'objets avec les nouvelles valeurs
                let result = []

                // Si on vient de la fiche cheval et qu'on a l'origine du cheval
                if(this.isAjoutChevalTunnel || this.isFirstMouvement) {
                    result.push({
                        type: 'provenance',
                        mouvement_label: this.origine_cheval,
                        mouvement_date: '0000-00-00',
                        mouvement_raison: this.getTrad('mouvement.lieu_origine_cheval'),
                        mouvement_cp: '',
                        mouvement_ville: '',
                        mouvement_adresse: '',
                        mouvement_type: 3,
                        mouvement_lieu: null, // pas de lieu id sur une provenance
                        horse_id: this.horse_id
                    })
                }

                // Si la provenance par défaut a été édité, cela signifie que le cheval a eu une autre sortie, entre la sortie enregistrée et l'entrée saisie
                if(this.askProvenance && this.hasChangeMouvProvenance) {
                    let provenance = {
                        type: 'provenance',
                        mouvement_label: this.provenance_mouv.lieu_label,
                        mouvement_date: this.mouvement_date,
                        mouvement_raison: this.getTrad('mouvement.lieu_origine_cheval'),
                        mouvement_cp: this.provenance_mouv.lieu_cp,
                        mouvement_ville: this.provenance_mouv.lieu_ville,
                        mouvement_adresse: this.provenance_mouv.lieu_adresse,
                        mouvement_type: 3,
                        horse_id: this.horse_id
                    }

					// Si le LIEU renseigné pour la provenance ne vient pas du carnet d'adresse, on enregistre un nouveau lieu
					if(this.askProvenance && this.hasChangeLieuProvenance) {
						result.push({
							type: 'lieu',
							lieu_label: this.provenance_lieu.lieu_label,
							lieu_adresse: this.provenance_lieu.lieu_adresse,
							lieu_cp: this.provenance_lieu.lieu_cp,
							lieu_ville: this.provenance_lieu.lieu_ville,
                            lieu_type: 3,
                            lieu_tag: 'provenance'
						})
                        provenance.lieu_tag = 'provenance'
                    }

                    result.push(provenance)
                }

                // Si la résidence proposée, lors d'une entrée sur la résidence, a été modifié, on l'enregistre comme un nouveau lieu typé résidence
                if(this.hasChangeResidenceLieu) {
                    result.push({
                        type: 'lieu',
                        lieu_label: this.lieu.lieu_label,
                        lieu_adresse: this.lieu.lieu_adresse,
                        lieu_cp: this.lieu.lieu_cp,
                        lieu_ville: this.lieu.lieu_ville,
                        lieu_type: this.lieu.lieu_type ? this.lieu.lieu_type : 4,
                        lieu_tag: 'mouvement'
                    })
                }

                // Si on a une date retour
                if(this.date_retour) {
                    result.push({
                        type: 'mouvement_retour',
                        date_retour: this.date_retour,
                    })
                }

                const mouv_to_save = this.compileMouvData()
                result.push(mouv_to_save)

                EventBus.$emit('MouvementAjout::genericReturnData', result)
            },

            displayCarnetAdresse() {
				this.ca_origine = 'lieu_mouvement'
                this.set_next_state()
            },

			displayCarnetAdresseProvenance() {
				this.ca_origine = 'provenance'
                this.set_next_state()
            },

			backFromCarnetAdresse(data) {
                this.lieu_from_ca = true;
                this.setObjectVars(data, true)
            },

			backupLieuResidence() {
                // On garde une copie du lieu, pour savoir s'il a été modifié
                this.lieu_bak = JSON.stringify(this.lieu)
            },

			backupLieuProvenance() {
				// On garde une copie de la provenance (lieu), pour savoir si elle a été édité
				this.provenance_lieu_bak = JSON.stringify(this.provenance_lieu)
			},

			backupMouvProvenance() {
				// On garde une copie de la provenance (mouvement), pour savoir si elle a été édité
				this.provenance_mouv_bak = JSON.stringify(this.provenance_mouv)
			},

			editProposedLieu() {
                this.edit_lieu_ca = true
            },

			setObjectVars(data, from_ca=false) {
	            if(this.ca_origine == 'provenance') {
					this.extractLieuData(data, 'provenance_mouv')
					this.extractLieuData(data, 'provenance_lieu')
					if (from_ca) this.backupLieuProvenance()
				}
				else if(this.ca_origine == 'lieu_mouvement') {
	                this.lieu.lieu_id    = data.lieu_id
					this.lieu.lieu_label = data.lieu_label
	                this.lieu.lieu_adresse = data.lieu_adresse
	                this.lieu.lieu_cp      = data.lieu_cp
	                this.lieu.lieu_ville   = data.lieu_ville
	                this.lieu.lieu_type    = data.lieu_type
	                this.backupLieuResidence() // pour le formulaire de mouvement normal, on viendra toujours du CA
				}
				this.ca_origine = ''
	        },

			extractMouvData(mouvement, type, with_mouv_infos=false) {
                this[type].lieu_id      = mouvement.mouvement_lieu
                this[type].lieu_label   = mouvement.mouvement_label
                this[type].lieu_adresse = mouvement.mouvement_adresse
                this[type].lieu_cp     = mouvement.mouvement_cp
                this[type].lieu_ville  = mouvement.mouvement_ville
                this[type].lieu_type   = mouvement.mouvement_type

                if(with_mouv_infos) {
                    // this.mouvement_date   = mouvement.mouvement_date
                    this.mouvement_raison = mouvement.mouvement_raison
                }
            },

			extractLieuData(lieu, type) {
				this[type].lieu_id      = lieu.lieu_id
				this[type].lieu_label   = lieu.lieu_label
				this[type].lieu_adresse = lieu.lieu_adresse
				this[type].lieu_cp 	  	= lieu.lieu_cp
				this[type].lieu_ville   = lieu.lieu_ville
				this[type].lieu_type    = lieu.lieu_type
            },

			compileMouvData() {
                return {
                    'mouvement_date'  : this.mouvement_date,
                    'mouvement_raison': this.mouvement_raison,
                    'mouvement_label' : this.lieu.lieu_label,
                    'mouvement_adresse': this.lieu.lieu_adresse,
                    'mouvement_cp'     : this.lieu.lieu_cp,
                    'mouvement_ville'  : this.lieu.lieu_ville,
                    'mouvement_type': this.lieu.lieu_type,
                    'mouvement_lieu': this.lieu.lieu_id,
                    'lieu_tag'      : 'mouvement',
                    'type'          : 'mouvement'
                }
            }
		},
		computed: {
            isAjoutChevalTunnel: function() {
                return this.from_asset == "horseAjout" || this.from_asset == "HorseCreation"
            },
			normalCase() {
				return !this.isAjoutChevalTunnel && !this.mouvement_id
			},
			singleAddHorse() {
				return this.horse_ids.length == 1 && this.horse_ids[0] !== -1
			},
			askProvenance() {
				const cond_prev_mouv = this.provenance_lieu && this.provenance_lieu.lieu_type != '' && this.provenance_lieu.lieu_type !== 1
				return this.lieu.lieu_type == 1 && this.normalCase && this.singleAddHorse && cond_prev_mouv
			},
			hasChangeMouvProvenance() {
				if(this.provenance_mouv_bak == '') return false
				if(this.provenance_mouv_bak != JSON.stringify(this.provenance_mouv)) return true

				return false
			},
			hasChangeLieuProvenance() {
				if(this.provenance_lieu_bak == '') return false
				if(this.provenance_lieu_bak != JSON.stringify(this.provenance_lieu)) return true

				return false
			},
			hasChangeResidenceLieu() {
                if(this.lieu_bak == '') return false
				if(this.lieu_bak != JSON.stringify(this.lieu)) return true

                return false
            },
            isFirstMouvement: function() { // S'il s'agit du premier mouvement du cheval (en dehors de la provenance)
				return !this.previous_mouv && this.singleAddHorse
			},
			horse_ids() {
				return this.horses
			},
			isWorking() {
				return this.prev_mouv_working || this.last_mouv_working || this.horse_resid_working
			}
		},
        asyncComputed: {
            can_define_retour: async function() {
                // Si on définit un mouvement vers une résidence, pas de date de retour possible
                if(this.lieu.lieu_type == 1) return false

                // Si on édit un mouvement, on ne peut pas définir de date de retour
                if(this.mouvement_id) return false

                // Si on est sur une séléction de plusieurs chevaux, on ne peut pas définir de date de retour (chaque cheval peut avoir un lieu actuel différent)
                // On regarde si le cheval est déjà dans une résidence. Si non, pas de date de retour possible pour le mouvement à insérer (le cheval ne peut pas avoir de date retour si le mouvement précédent est un concours, par ex)
                if(this.$route.params.horse_id != undefined) {
					if(this.horse_ids.length != 1) {
						return false
					}

					// Si plusieurs chevaux de sélectionnés
					if(this.horse_ids[0] !== -1) {
						const horse_residence = await this.getHorseLastResidence(this.horse_ids[0])
						if(!horse_residence) {
							return false
						}
					}
                }
                return true
            }
        },
        watch: {
            'state' (val) {
                // Si l'état actuel correspond au formulaire d'ajout de mouvement, on push la route
                if(val == 'carnet_adresse') {
                    this.$emit('update:openCarnetAdresse', true)
					// const only_lieux = this.ca_origine == 'provenance'
					// const only_resid = this.isAjoutChevalTunnel
                    // this.$router.push({ name: 'lieu_choice_mv', params: { lieu_id: this.lieu_id, only_lieux: only_lieux, only_residence: only_resid } })
                }
            },
            '$route' (to, from) {
                // Si on revient dans la vue mère (via le goBack())
                if(to.name == this.$options.name) {
                    this.set_last_state()
                }
            },
            'can_define_retour' (val, prev) {
                if(!val) {
                    this.date_retour_checked = false
                }
				this.horse_resid_working = false
            },
			'isFirstMouvement' (val) {
                // Si on est sur une modification de mouvement, on garde le type du lieu
                if(this.mouvement_id) return

                // S'il s'agit du tout premier mouvement
                if(val) {
                    this.lieu.lieu_type = 1
                }
                else {
                    // Si le mouvement précédent est une entrée, l'actuel sera donc une sortie
                    if(this.previous_mouv && this.previous_mouv.mouvement_type == 1) {
                        this.lieu.lieu_type = 3
                    }
                    else if(this.singleAddHorse) {
                        this.lieu.lieu_type = 1
                    }
                }
            },
			'provenance_lieu': {
				handler(val) {
					this.provenance_mouv = val
				},
				deep: true
			}
        },
        components: {
            ErrorAlert,
            LoadingSpinner,
			AskProvenance
        }
	};
</script>
