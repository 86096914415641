import _defaults from 'lodash/defaults'

export default {
    methods: {
        getMvtShutters(props) {
            return {
                'horse-selection': {
                    name: 'horse-selection',
                    title: this.$t('acte.choix_chevaux'),
                    cmpPath: 'components/Horse/HorseSelectionV2',
                    onOk: (horse_ids) => {
                        this.openMvtAddForm(horse_ids)
                    },
                },
                'mvt-add-form': {
                    name: 'mvt-add-form',
                    title: this.$t('mouvement.ajouter_mouvement'),
                    cmpPath: 'components/WriteMouvement',
                    props,
                    onOk: this.genericReturnData
				},
				'mvt-horse-history': {
                    name: 'mvt-horse-history',
                    title: this.$t('mouvement.historique_mouvement'),
                    cmpPath: 'components/ShutterScreens/Horse/HistoriqueMouvement',
					props,
					noClass: true
                },
                'mvt-interne-horse-history': {
                    name: 'mvt-interne-horse-history',
                    title: this.$t('mouvement.historique_interne'),
                    cmpPath: 'components/ShutterScreens/Horse/HistoriqueInterne',
                    props,
                    noClass: true
                },
				'mvt-horse-residence': {
                    name: 'mvt-horse-residence',
                    title: this.$t('mouvement.ajouter_mouvement'),
                    cmpPath: 'components/ShutterScreens/Horse/HorseResidence',
                    props,
                    onOk: this.genericReturnData
                },
                'carnet-adresse': {
 					name: 'carnet-adresse',
                    title: this.$t('mouvement.carnet_adresse'),
					cmpPath: 'components/CarnetAdresse',
					props,
                    onOk: this.lieuChoosed,
                },
                'lieu-add-form': {
                    name: 'lieu-add-form',
                    title: this.$t('lieu.ajouter'),
                    cmpPath: 'components/AjoutLieu',
                    props,
                    onOk: this.lieuFormSubmit,
                },
                'lieu-edit-form': {
                    name: 'lieu-edit-form',
                    title: this.$t('lieu.edit'),
                    cmpPath: 'components/AjoutLieu',
                    onOk: this.lieuFormSubmit,
                    props
                },
                'mvt-lieu-stationnement': {
                    name: 'mvt-lieu-stationnement',
                    title: this.$t('mouvement.stationnement_edit'),
                    cmpPath: 'components/ShutterScreens/Horse/EditLieuStationnement',
                    onOk: this.lieuStationnementSubmit,
                    props
                },
                'ajout-tiers': {
                    name: 'ajout-tiers',
                    title: this.$t('tiers.ajouter_tiers'),
                    cmpPath: 'components/ShutterScreens/Tier/FormAdd',
                    onOk: this.setTier,
                    props
                },
                'contact-add-form': {
                    name: 'contact-add-form',
                    title: this.$t('monte.create_contact'),
                    cmpPath: 'components/AjoutContact', 
                    onOk: this.contactFormSubmit,
                },
                'localisation-residence': {
                    name: 'localisation-residence',
                    title: this.$t('lieu.localisation_residence'),
                    cmpPath: 'components/Localisation/SelectResidence'
                }
            }
        },

        openHorseSelection() {
            const shutters = this.getMvtShutters()
            this.shutterPanel().open(shutters['horse-selection'])
        },

        openMvtAddForm(horses_ids) {
            if (horses_ids) {
                const shutters = this.getMvtShutters({
                    horses_ids
                })
                this.shutterPanel().open(shutters['mvt-add-form'])
            }
		},

		openMvtHorseHistory(horse_id) {
			const shutters = this.getMvtShutters({ horse_id })
            this.shutterPanel().open(shutters['mvt-horse-history'])
		},

        openMvtInterneHorseHistory(horse_id) {
            const shutters = this.getMvtShutters({ horse_id })
            this.shutterPanel().open(shutters['mvt-interne-horse-history'])
        },
		
		openMvtHorseResidence(horse_id) {
			const shutters = this.getMvtShutters({ horse_id })
            this.shutterPanel().open(shutters['mvt-horse-residence'])
		},

        openCarnetAdresse(params) {
			const paramsDefaults = _defaults(params, {
				can_chose_location: true,
				residences_only: false,
				can_manage_registry_gen: false
			})

        	const shutters = this.getMvtShutters(paramsDefaults)
            this.shutterPanel().open(shutters['carnet-adresse'])
        },

		openAjoutLieu(type = "") {
            const shutters = this.getMvtShutters({type: type})
            this.shutterPanel().open(shutters['lieu-add-form'])
        },    

        openEditLieu(lieu_id) {
            if (lieu_id) {
                const shutters = this.getMvtShutters({
                    lieu_id
                })
                this.shutterPanel().open(shutters['lieu-edit-form'])
            }
        },   

        openChangeLieuStationnement(horse_id) {
            const shutters = this.getMvtShutters({ horse_id })
            this.shutterPanel().open(shutters['mvt-lieu-stationnement'])
        },

        openAddTier() {
            const shutters = this.getMvtShutters()
            this.shutterPanel().open(shutters['ajout-tiers'])
        },

        openAjoutContact() {
            const shutters = this.getMvtShutters()
            this.shutterPanel().open(shutters['contact-add-form'])
        },

        setUpLocalisationResidence() {
            const shutters = this.getMvtShutters()
            this.shutterPanel().open(shutters['localisation-residence'])
        }
    }
}
