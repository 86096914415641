import Transformer from './Transformer'

export default class InvoiceDetailsTransformer extends Transformer {

	table = 'invoice_details'

    contract(item) {
        if(!item.invoicedetails_contract) {
            return []
        }

        return this.db().t('contract')
		.then(table => {
			return table.where({
				contract_id: parseInt(item.invoicedetails_contract)
			}).first()
		})
		.then(contract => {
            let res = []
            if(contract) {
                res.push({
                    'contract_id': contract.contract_id,
                    'contract_num': contract.contract_num
                })
            }

            return res
		})
    }

    fetchWithOnlyContract(col) {
        this.additionalColumns({
            'contract': this.contract
		})

        return col
    }

    async transformWithOnlyContract(invoice_details) {
        return {
            'invoicedetails_id': invoice_details.invoicedetails_id,
			'contract': invoice_details.contract[0] ? invoice_details.contract[0] : null
        }
	}
}