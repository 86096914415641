import Common from '@/assets/js/common.js'
import Constants from '../../config.local.js'
import Mouvement from '@/mixins/Mouvements.js'
import LieuTransformer from '@/assets/js/dexie/transformers/LieuTransformer.js'
import LieuTypeTransformer from '@/assets/js/dexie/transformers/LieuTypeTransformer.js'
import LieuCleaner from '@/assets/js/cache/cleaners/LieuCleaner.js'

var LieuMixin = {
    mixins: [Mouvement],

	methods: {
        /* Retourne la liste complète des lieux "visibles" (lieux enregistrés dans le carnet d'adresse, et non un mouvement) */
        getLieux: async function(transformer = true) {
			return this.$storage.db.t('lieu')
            .then(table => {
                return table.where({
                    lieu_valide: 1,
                })
            })
            .then(col => {
            	if(transformer) {
	                return col.transform(new LieuTransformer('withType'))
            	}
            	return col.toArray()
			})
			.then(res => {
				if(transformer) {
					return res.filter(lieu => lieu.lieu_type.lieutype_visible == true)
				}
				return res
			})
        },

        /* Retourne un lieu, à partir de son id */
        getLieuById: async function(lieu_id) {
			return this.$storage.db.t('lieu')
            .then(table => {
                return table.get(parseInt(lieu_id))
            })
            .then(item => {
				return LieuTransformer.process(item)
			})
        },

        /* Retourne tous les types de lieux possibles */
        getTypeLieu: async function() {
			return this.$storage.db.t('lieu_type')
            .then(table => {
                return table.toCollection()
            })
            .then(col => {
                return col.transform(new LieuTypeTransformer())
			})
        },

        /* Retourne la liste des lieux établis comme résidences valides */
        getResidences: async function() {
			return this.getLieux()
				.then(res => {
					return res.filter(lieu => lieu.lieu_type.lieutype_id === 1)
				})
		},

        /* Retourne la dernière résidence saisie */
        getLastResidence: async function() {
			return this.$storage.db.t('lieu')
            .then(table => {
				return table.where({
					lieu_valide: 1,
					lieu_type: 1
				})
				.reverse()
				.limit(1)
            })
            .then(col => {
                return col.transform(new LieuTransformer())
			})
			.then(res => {
				if(Array.isArray(res)) return res[0]
				return res
			})
        },

        /* Retourne la liste des lieux enregistrées */
        getOtherLieux: async function() {
			return this.getLieux()
			.then(res => {
				return res.filter(lieu => lieu.lieu_type.lieutype_id !== 1)
			})
		},

		getLieuTypeVisibles() {
			return this.$storage.db.t('lieu_type')
			.then(table => {
				return table.where({
					lieutype_visible: 1
				})
			})
			.then(col => {
				return col.primaryKeys()
			})
		},

        /* Insert un lieu */
        saveLieu: async function(params) {
			const lieu_id = Common.getNegativeId()

			await this.$storage.db.t('lieu')
			.then(table => {
				return table.add({
					lieu_id: lieu_id,
					lieu_label	 : params.lieu_label,
					lieu_adresse : params.lieu_adresse,
					lieu_cp		 : params.lieu_cp,
					lieu_ville	 : params.lieu_ville,
					lieu_type	 : parseInt(params.lieu_type),
					lieu_contact : parseInt(params.lieu_contact),
					lieu_registry_enable: true,
					lieu_valide  : 1
				})
			})
		
			// On retourne l'objet créé
			params.lieu_id = lieu_id
			return params
        },

        /* Update d'un lieu */
        updateLieu: async function(lieu_id, params) {
			await this.$storage.db.transaction(
                'rw',
                ['lieu'],
                async () => {
					await this.$storage.db.t('lieu')
					.then(table => {
						return table.update(
							parseInt(lieu_id), {
							lieu_label	 : params.lieu_label,
							lieu_adresse : params.lieu_adresse,
							lieu_cp		 : params.lieu_cp,
							lieu_ville	 : params.lieu_ville,
							lieu_type 	 : parseInt(params.lieu_type),	
							lieu_contact : parseInt(params.lieu_contact)					
						})
					})
			  
					// On retourne l'objet modifié
					params.lieu_id = lieu_id
					return params
				}
			)

			// Vider le cache des tableaux qui ont une relation vers le lieu
			LieuCleaner.inst().onMutation([lieu_id])
        },

		/* Change l'option sur une résidence pour la génération du registre d'élevage */
		changeLieuGenRegistryState: async function(lieu_id, state=1) {
			return this.$storage.db.transaction(
                'rw',
                ['lieu'],
                async () => {
					await this.$storage.db.t('lieu')
					.then(table => {
						return table.update(
							parseInt(lieu_id), {
								lieu_registry_enable: state
						})
					})
				}
			)
		},

        deleteLieu: async function(lieu_ids) {
			const parsed_ids = lieu_ids.map(id => parseInt(id))

			// On regarde si ces lieux sont des types résidences, et s'ils sont utilisés pour des mouvements
			const lieux = await this.$storage.db.t('lieu')
				.then(table => {
					return table.where('lieu_id')
						.anyOf(parsed_ids)
				})
				.then(col => {
					return col.filter(lieu => lieu.lieu_type === 1)
				})
				.then(col => {
					return col.toArray()
				})
			
			// Pour tous ces lieux de type résidence, si on a un lien avec un mouvement, on throw une erreur
			const residence_lieux_ids = lieux.map(lieu => lieu.lieu_id)
				
			const is_used = await this.$storage.db.t('horse_mouvement')
			.then(table => {
				return table.where('mouvement_lieu')
					.anyOf(residence_lieux_ids)
			})
			.then(col => {
				return col.count()
			})
			.then(quantity => {
				return quantity > 0
			})

			if(is_used) {
				throw "lieu_used"
			}

			return this.$storage.db.transaction(
                'rw',
                ['lieu'],
                async () => {
					await  this.$storage.db.t('lieu')
					.then(table => {
						return table.where('lieu_id')
						.anyOf(parsed_ids)
						.invalid()
					})
			  
					// On retourne les ids d'objets supprimés
					return lieu_ids
				}
			)
        },

        getHorseResidenceDate: async function(lieu_ids, intraloc_ids, date_debut, date_fin, force){
        	const force_url = "&force="+force
			const url = Constants.MOUVEMENTS_HORSE_DATE_URL + "?licence_key="+Constants.USER_LICENCE_KEY + "&date_debut=" + date_debut+ "&date_fin=" + date_fin + force_url

			const params = {
				lieu_ids,
				intraloc_ids
			}

			return this.$request.request_post_api("LieuMixin::getHorseResidenceDate", url, params, false)
				.then(res => {
					return res.retour
				})
				.catch(error => {
					console.log("LieuMixin::getHorseResidenceDate => ERROR", error)
					return []
				})
		},

		getDefaultLieu: async function() {
			return this.$storage.db.t('lieu')
				.then(table => {
					return table.where({
						lieu_default: 1
					})
				})
				.then(col => {
					return col.transform(new LieuTransformer())
				})
		},

		archiveLieuxByIds: async function(lieu_ids) {
			const parsed_ids = lieu_ids.map(id => parseInt(id))

			return await this.$storage.db.t('lieu')
				.then(table => {
					return table.where('lieu_id')
					.anyOf(parsed_ids)
					.modify({lieu_archive: 1})
				})
		},

		setLieuByDefault: async function(lieu) {
			if(lieu.lieu_type && lieu.lieu_type.lieutype_id == 1) {
				const previous_default_lieu = await this.getDefaultLieu()
				if(previous_default_lieu.length > 0) {
					await this.$storage.db.t('lieu')
					.then(table => {
						return table.update(parseInt(previous_default_lieu[0].lieu_id), {
							lieu_default: 0
						})
					})
				}		

				return this.$storage.db.t('lieu')
				.then(table => {
					return table.update(parseInt(lieu.lieu_id), {
						lieu_default: 1
					})
				})
			}
			else {
				this.failureToast("toast.only_residence")
			}
		},

		uploadDocumentLieu: async function(lieu_id, document_file) {
			const url = this.constructRoute(Constants.LIEUX_DOCUMENT_URL, {lieu_id}) + "?licence_key="+Constants.USER_LICENCE_KEY

			return this.$request.request_post_file_api("LieuxMixins::uploadDocumentLieu", url, [{ 
					name: 'document',
					content: document_file
				}])
				.catch(e => {
					console.error("LieuxMixins::uploadDocumentLieu => ERROR", e)
					return null
				})
				.then(res => {
					return res.retour
				})
		},

		getDocumentByLieu: async function(lieu_id) {
			const url = this.constructRoute(Constants.LIEUX_DOCUMENT_URL, {lieu_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("LieuxMixins::getDocumentByLieu", url)
			if(result) return result.retour
			return null
		},

		uploadImageLieu: async function(lieu_id, document_file) {
			const url = this.constructRoute(Constants.LIEUX_IMAGE_URL, {lieu_id}) + "?licence_key="+Constants.USER_LICENCE_KEY

			return this.$request.request_post_file_api("LieuxMixins::uploadImageLieu", url, [{ 
					name: 'document',
					content: document_file
				}])
				.catch(e => {
					console.error("LieuxMixins::uploadImageLieu => ERROR", e)
					return null
				})
				.then(res => {
					return res.retour
				})
		},

		getIntraLocationsWithActeByResidence: async function(lieu_id, date, contacts_ids) {
			let url = this.constructRoute(Constants.LIEUX_LOCALISATION_ACTE_URL, {lieu_id}) + "?licence_key="+Constants.USER_LICENCE_KEY+'&date='+date
			if(contacts_ids.length > 0) {
				url += '&contacts_ids='+contacts_ids.join(',')
			}

			const result = await this.$request.request_get_api("LieuxMixins::getIntraLocationsWithActeByResidence", url)
			if(result) return result.retour
			return null
		},
	}
}

export default LieuMixin
