var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('shutter-panel',{ref:"mouvementPanel",attrs:{"shutters":[
                {
                    name: 'write-mouvement',
                    title: this.$t('acte.trigger.declencher.mouvement'),
                    default: false
                },
                {
                    name: 'carnet-adresse',
                    title: this.$t('mouvement.carnet_adresse'),
                    default: false
                } ]},scopedSlots:_vm._u([{key:"write-mouvement",fn:function(){return [_c('WriteMouvementTriggered',{attrs:{"horses":_vm.horses,"date_acte":_vm.date_acte,"addOrEditReady":_vm.addOrEditReady,"openCarnetAdresse":_vm.openCarnetAdresse},on:{"update:openCarnetAdresse":function($event){_vm.openCarnetAdresse=$event},"update:open-carnet-adresse":function($event){_vm.openCarnetAdresse=$event}}})]},proxy:true},{key:"carnet-adresse",fn:function(){return [_c('CarnetAdresseTriggered',{attrs:{"addOrEditReady":_vm.addOrEditReady}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }